@import 'assets/styles/_mixins.scss';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &_field {
    &__error {
      background: $red !important;
    }
  }

  &__fields_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    @include maxWidth($tablet-s) {
      gap: 20px;
    }

    @include maxWidth($mobile-l) {
      gap: 12px;
    }
  }

  &__text_field {
    width: 100%;
    padding: 13px 14px 15px;
    background: $black-light;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    color: $white;
    border-radius: 10px;

    &::placeholder {
      color: $placeholder-color;
    }

    @include maxWidth($tablet-s) {
      border-radius: 8px;
      font-size: 20px;
      line-height: 140%;
    }

    @include maxWidth($mobile-xl) {
      padding: 12px 14px 14px;
      border-radius: 6px;
      font-size: 17px;
    }

    @include maxWidth($mobile-l) {
      border-radius: 4px;
      font-size: 13px;
    }
  }

  &__textarea {
    height: 212px;
    border: none;
  }

  &__upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;
  }

  &__custom {
    border: none;
    color: $placeholder-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    background: url('/images/selectFieldArrow.png') 97% center no-repeat, $black-light;
    cursor: pointer;

    @include maxWidth($tablet-s) {
      background: url('/images/selectFieldArrowMobile.png') 97% center no-repeat, $black-light;
    }
  }

  &__label {
    position: relative;
    width: 100%;

    &__helper_text {
      font-size: 24px;
      line-height: 130%;
      color: $gray-text;

      @include maxWidth($tablet-s) {
        font-size: 20px;
      }

      @include maxWidth($mobile-xl) {
        font-size: 17px;
      }

      @include maxWidth($mobile-l) {
        font-size: 13px;
      }
    }

    &__upload {
      padding: 13px 14px 15px 14px;
      width: 100%;
      min-height: 59px;
      height: fit-content;
      border: 2px dashed $black-light;
      border-radius: 10px;
      background: transparent;
      cursor: pointer;
    }
  }

  &__error {
    padding: 13px 14px 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    color: $red;
    text-align: start;

    @include maxWidth($tablet-s) {
      font-size: 20px;
    }

    @include maxWidth($mobile-xl) {
      font-size: 17px;
    }

    @include maxWidth($mobile-l) {
      font-size: 13px;
    }

    &__upload {
      padding: 13px 14px 0 0;
    }
  }

  &__button {
    margin-top: 37px;

    @include maxWidth($mobile-xl) {
      margin-top: 31px;
    }
  }
}
