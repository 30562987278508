@import 'assets/styles/_mixins.scss';

.compare_table {
  margin-top: 82px;
  display: flex;
  flex-flow: column nowrap;
  max-width: 1618px;
  gap: 5px;

  &__machine_descr {
    @include maxWidth($mobile-xl) {
      margin-top: 10px;
      font-size: 20px;
    }

    @include maxWidth($mobile-xl) {
      @include p1;
    }
  }

  &__row {
    padding: 10px 27px 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    text-align: start;
    border-radius: 4px;

    @include maxWidth($desktop-s) {
      padding: 8px 27px;
      gap: 32px;
    }

    @include maxWidth($mobile-l) {
      padding: 5px 0;
      gap: 22px;
    }

    &_item {
      display: flex;
      flex-direction: column;
      font-family: 'Monument Grotesk';
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: $placeholder-color;
      width: 100%;

      @include maxWidth($desktop-s) {
        font-size: 22px;
      }

      & span {
        color: $white;
      }

      &:first-child {
        flex-shrink: 0;
        max-width: 202px;
        color: $gray-text;
        max-width: 30%;
      }

      &:nth-child(2) {
        max-width: 70%;
      }

      &:nth-child(3) {
        max-width: calc(28% - 41px);

        @include maxWidth($desktop-s) {
          max-width: calc(28% - 31px);
        }
      }

      @include maxWidth(600) {
        font-size: 20px;
      }

      @include maxWidth($mobile-xl) {
        font-size: 18px;
      }

      @include maxWidth($mobile-l) {
        @include p1;
      }
    }
  }

  &__header {
    margin-bottom: 31px;
    padding: 10px 27px 10px;
    gap: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;

    @include maxWidth($desktop-s) {
      padding: 8px 27px 8px;
      gap: 32px;
    }

    & h3 {
      margin-top: 40px;
      color: $white;
    }

    @include maxWidth($mobile-l) {
      gap: 12px;
    }

    @include maxWidth($mobile-xl) {
      & li:nth-child(2) {
        width: 270px;
        height: 500px;
        justify-content: flex-end;
      }
    }

    @include maxWidth($mobile-l) {
      & li:nth-child(2) {
        height: 470px;
      }
    }
  }

  &__bottom {
    margin-top: 26px;
    padding: 10px 27px 10px;
    gap: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: start;

    & li {
      align-items: center;

      @include maxWidth($tablet-s) {
        max-width: unset !important;
        width: unset;
      }
    }

    @include maxWidth($desktop-s) {
      padding: 8px 27px 8px;
      gap: 32px;
    }

    @include maxWidth($mobile-xl) {
      & li {
        max-width: none !important;
      }
    }

    &_title {
      margin-bottom: 18px;
      color: $white;
    }

    &_link {
      width: fit-content;
      margin-top: 44px;
      padding: 7px 16px 12px;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: $white;
      background-color: $black-light;
      border-radius: 10px;
      transition: opacity 0.12s ease;

      &:hover {
        opacity: 0.7;
      }
    }

    &_rating {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: $gray-text;

      @include maxWidth($mobile-xl) {
        font-size: 13.1423px;
      }

      &_number {
        transform: translateY(-3px);
      }
    }
  }
}
