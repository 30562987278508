@import 'assets/styles/_mixins.scss';

.login_extra {
  margin: 11px 0 34px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: $gray-text;

  @include maxWidth($tablet-s) {
    font-size: 15px;
  }

  @include maxWidth($mobile-l) {
    margin: 16px 0 22px;
    font-size: 11px;
  }

  &__remember {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__forgot_password {
    &:hover {
      opacity: 0.8;
    }
  }
}
