@import './assets/styles/_mixins.scss';

.games_slider {
  &__swiper {
    margin: 64px 0 90px;
    width: 100%;
    max-width: 1538px;

    @include maxWidth($tablet-l) {
      margin: 32px 0 24px;
    }

    @include maxWidth($tablet-s) {
      bottom: 0;
      margin: 0 0 5px;
    }

    :global(.swiper-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8.81px;
      position: relative !important;
      margin-top: 0 !important;
      width: fit-content;
      margin: 0 auto;
      bottom: 53px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 35.2593px;

      @include maxWidth($tablet-l) {
        padding: 7px;
      }

      @include maxWidth($tablet-s) {
        bottom: 35px;
      }
    }

    :global(.swiper-pagination-bullet) {
      height: 12.12px;
      width: 12.12px;
      margin: 0 5.29px;
      background-color: rgba(255, 255, 255, 0.7) !important;
      opacity: 1;

      @include maxWidth($tablet-l) {
        height: 10px !important;
        width: 10px !important;
      }

      @include maxWidth($tablet-s) {
        height: 8px !important;
        width: 8px !important;
      }

      @include maxWidth($mobile-xl) {
        background-color: #393939 !important;
      }
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: blue !important;
    }
  }
}

.swiper-pagination {
  position: relative !important;
  margin-top: 0 !important;
  bottom: 40px;
}
