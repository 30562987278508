@import 'assets/styles/_mixins.scss';

.machines_compare_page {
  &__cross {
    position: absolute;
    top: 12px;
    right: 100px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: $black-light;
    transition: opacity 0.12s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    @include maxWidth($desktop-s) {
      width: 50px;
      height: 50px;
    }

    @include maxWidth($tablet-l) {
      top: 8px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 35px;
      height: 3px;
      border-radius: 20px;
      background-color: $white;

      @include maxWidth($desktop-s) {
        width: 26px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 35px;
      height: 3px;
      border-radius: 20px;
      background-color: $white;

      @include maxWidth($desktop-s) {
        width: 26px;
      }
    }
  }

  &__machine {
    &_name {
      margin-top: 50px;
      color: $white;
    }

    &_descr {
      margin-top: 11px;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: $placeholder-color;

      @include maxWidth($desktop-s) {
        font-size: 22px;
      }
    }

    &_image {
      position: relative;
      width: 419px;
      height: 465px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-135px);

      &__img {
        transform: translateX(20px);

        @include maxWidth($tablet-l) {
          transform: translateX(10px);
        }

        @include maxWidth($mobile-l) {
          width: 283px !important;
          height: 509px !important;
          min-width: unset !important;
          min-height: unset !important;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #ff9f0e 0%,
          #ff1f8a 31.25%,
          rgba(53, 98, 255, 0.58) 66.67%,
          rgba(53, 98, 255, 0) 100%
        );
        opacity: 0.9;
        z-index: -1;
      }

      //   &__nevada {
      //   }

      //   &__neostar {
      //   }

      //   &__riogrande {
      //   }
    }
  }
}
