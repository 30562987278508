@import './assets/styles/_mixins.scss';

.slider_item {
  position: relative;
  width: 100%;
  max-width: 1538px;
  aspect-ratio: 1538/549;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include maxWidth($mobile-xl) {
    padding-bottom: 24px;
    aspect-ratio: 331/190;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  &__banner {
    display: none;
    z-index: -1;
    border-radius: 28px;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include maxWidth($tablet-s) {
      border-radius: 14px;
    }

    &.desktop {
      display: block;

      @include maxWidth($mobile-xl) {
        display: none;
      }
    }

    &.mobile {
      display: none;
      border-radius: 6.54971px;

      @include maxWidth($mobile-xl) {
        display: block;
      }
    }
  }

  &__descr {
    padding: 10px 22px 15px;
    background-color: $red-2;
    border-radius: 50px;

    @include maxWidth($desktop-s) {
      font-size: 22px;
    }

    @include maxWidth($tablet-l) {
      padding: 8px 16px 12px;
      font-size: 16px;
    }

    @include maxWidth($mobile-xl) {
      padding-bottom: 12px;
      font-size: 20px;
    }

    @include maxWidth($mobile-l) {
      padding: 4.57329px 10.0612px 6.85994px;
      font-weight: 700;
      font-size: 13.7199px;
      line-height: 100%;
      letter-spacing: -0.02em;
      border-radius: 22.8664px;
    }
  }
  &__title {
    margin-top: 17px;

    @include maxWidth($tablet-m) {
      margin-top: 10px;
      font-size: 38px;
    }

    @include maxWidth($tablet-s) {
      margin-top: 8px;
      font-size: 32px;
    }

    @include maxWidth($mobile-xl) {
      @include h2;
      margin-top: 4px;
      font-size: 32px;
    }
  }

  &__text_content {
    position: absolute;
    top: 50px;
    left: 72px;

    @include maxWidth($desktop-s) {
      top: 40px;
      left: 50px;
    }

    @include maxWidth($tablet-l) {
      top: 35px;
      left: 40px;
    }

    @include maxWidth($tablet-s) {
      top: 20px;
      left: 30px;
    }

    @include maxWidth($mobile-xl) {
      top: 11px;
      left: 18px;
    }
  }

  &__category {
    position: absolute;
    bottom: 67px;
    left: 72px;

    @include maxWidth($desktop-s) {
      bottom: 40px;
      left: 50px;
    }

    @include maxWidth($tablet-l) {
      bottom: 30px;
      left: 40px;
    }

    @include maxWidth($tablet-s) {
      bottom: 20px;
      left: 30px;
    }

    @include maxWidth($mobile-xl) {
      bottom: 37px;
      left: 18px;
    }
  }
}
