@import 'assets/styles/_mixins.scss';

.slider {
  .swiperSlide {
    height: 800px;
    min-width: 300px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      #ff9f0e 0%,
      #ff1f8a 31.25%,
      rgba(53, 98, 255, 0.58) 66.67%,
      rgba(53, 98, 255, 0) 100%
    );
    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.05);

      .swiperSlide__labelLink {
        color: $white;
      }
      .swiperSlide__labelLink_rightArrow {
        background-color: $white;
      }
    }

    @include maxWidth($mobile-xl) {
      height: 500px;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      @include maxWidth($tablet-l) {
        pointer-events: none;
      }
    }

    &__labelLink {
      position: relative;
      font-weight: 500;
      font-size: 40px;
      line-height: 56px;
      letter-spacing: -0.03em;
      color: $gray-text;
      display: flex;
      gap: 10px;
      align-items: center;
      @include maxWidth($desktop-s) {
        color: $white;
      }
      @include maxWidth($mobile-xl) {
        font-size: 18px;
        line-height: 22px;
      }

      &_rightArrow {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $gray-text;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          transform: rotate(180deg) scale(1.5);
          path {
            fill: black;
          }
        }
        @include maxWidth($desktop-s) {
          background-color: $white;
        }
        @include maxWidth($mobile-xl) {
          width: 20px;
          height: 20px;
          svg {
            transform: rotate(180deg) scale(1);
          }
        }
      }
    }
  }
}
