@import 'assets/styles/_mixins.scss';
@import 'assets/styles/vars/_scss-variables.scss';

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 101;
  visibility: hidden;
  opacity: 0;

  //   transition: visibility 0, opacity 0 ease;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  user-select: none;

  &__open {
    background: $black;
    opacity: 0.6;
    visibility: visible;
    pointer-events: all;
    transition: visibility 0s, opacity 0s ease;
    overflow: hidden;
  }
}

.modal {
  opacity: 0;
  visibility: hidden;
  z-index: 111111;
  transition: visibility 0.5s, opacity 0s ease;

  &__open {
    opacity: 1;
    visibility: visible;
  }
  &__content {
    position: fixed;
    z-index: 111111;
    top: 15%;
    left: 50%;
    transform: translate(-50%, 0);
    background: radial-gradient(65.17% 47.21% at 50% -8.51%, #b12c97 0%, rgba(19, 6, 53, 0) 100%),
      radial-gradient(47.67% 47.67% at 50% 100%, #0601af 0%, rgba(14, 5, 77, 0) 100%),
      linear-gradient(180deg, #1c082c 0%, #030252 100%);
    border-radius: 30px;
    width: 100%;
    max-width: 812px;
    height: fit-content;
    max-height: 90vh;
    padding: 30px;
    overflow-y: auto;

    @include maxWidth(950) {
      max-width: 95vw;
    }
  }
}
