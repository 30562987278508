@import './assets/styles/mixins';

.news_page {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  &__container {
    max-width: 1148px;
  }

  &__title {
    text-align: center;

    @include maxWidth($mobile-xl) {
      & span {
        display: block;
      }
    }
  }
}

.mobile {
  display: none;

  @include maxWidth($tablet-l) {
    display: block;
  }
}
