@import './assets/styles/_mixins.scss';

.banners_section {
  width: 100%;
  margin: 56px 0 82px;
  display: flex;
  align-items: center;
  gap: 27px;

  @include maxWidth($tablet-l) {
    margin: 56px 0 24px;
  }

  @include maxWidth($tablet-s) {
    gap: 20px;
    margin: 56px 0 5px;
    flex-flow: row wrap;
  }

  @include maxWidth($mobile-xl) {
    padding-right: 15px;
  }

  &__banner {
    position: relative;
    width: calc(50% - 15.5px);
    aspect-ratio: 752/427.59;

    a {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    @include maxWidth($tablet-s) {
      width: 100%;
    }

    &_category {
      position: absolute;
      padding: 10px 22px 15px;
      background-color: $red-2;
      // background-color: $black;
      border-radius: 50px;
      left: 28px;
      top: 17.29px;

      // &__transparent {
      //   background-color: transparent;
      // }

      @include maxWidth($desktop-s) {
        font-size: 22px;
      }

      @include maxWidth($tablet-l) {
        padding: 8px 16px 12px;
        font-size: 16px;
        left: 18px;
      }

      @include maxWidth($tablet-s) {
        padding: 10px 22px 15px;
        left: 28px;
        top: 17.29px;
        font-size: 24px;
      }

      @include maxWidth($mobile-xl) {
        padding-bottom: 12px;
        left: 18px;
        top: 12px;
        font-size: 20px;
      }

      @include maxWidth($mobile-l) {
        left: 12.8px;
        top: 7.91px;
        padding: 4.57329px 10.0612px 6.85994px;
        font-weight: 700;
        font-size: 13.7199px;
        line-height: 100%;
        letter-spacing: -0.02em;
        border-radius: 22.8664px;
      }
    }

    &_image {
      z-index: -1;
      border-radius: 20px;
      overflow: hidden;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include maxWidth($mobile-xl) {
        border-radius: 9px;
      }
    }
  }
}
