@import 'assets/styles/_mixins.scss';

.checkbox {
  &_label {
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
    flex-shrink: 0;

    &--m {
      width: 22px;
      height: 22px;

      & .checkbox_custom {
        border-radius: 5px;
      }

      & .checkbox_custom::after {
        width: 7px !important;
        height: 11px !important;
      }
    }
  }

  &_input {
    opacity: 0;

    &:checked + .checkbox_custom {
      background-color: $blue;

      &::after {
        content: '';
        border: 2px solid #fff;
        border-left: none;
        border-top: none;
        width: 6px;
        height: 9px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  &_custom {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid $gray-text;
    border-radius: 2px;
  }
}
