@import './assets/styles/mixins';

.download_promo {
  width: 100%;
  margin-top: 65px;
  padding: 17.8402px 24.82px 18.8313px 19.8224px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14.19px;
  border-radius: 8.38533px;
  background: $blue;
  font-weight: 700;
  font-size: 35.1464px;
  line-height: 29px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: $white;

  @include maxWidth($tablet-s) {
    font-size: 28px;
  }

  @include maxWidth($mobile-xl) {
    margin-top: 36px;
    padding: 11.177px 15.5499px 11.7979px 12.4189px;
    font-size: 21px;
  }

  &__icon {
    width: 37px;
    height: 37px;

    @include maxWidth($mobile-xl) {
      width: 20px;
      height: 20px;
    }
  }
}
