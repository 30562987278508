@import './assets/styles/_mixins.scss';

.burger_menu {
  top: 0;
  right: 0;
  position: fixed;
  z-index: 150;
  // width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 48px 62px;
  background: $black;
  transform: translateX(100vw);
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.4s ease;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);

  &__open {
    opacity: 1;
    transform: translateX(0);

    &__tablet {
      width: 70vw;
    }

    &__mobile {
      width: 100vw;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    position: absolute;
    right: 35px;
    top: 25px;
    gap: 42px;
  }

  &__close {
    width: 20px;
    height: 20px;
  }

  &__nav {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-height: 500px) {
      margin-top: 8vh;
    }
  }

  &__item {
    position: relative;

    &_paragraph {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 140%;
      letter-spacing: -0.03em;
      color: $white;
    }

    &_child {
      margin-bottom: 10px;
      position: relative;
      padding-left: 20px;

      &__client {
        margin-top: 0 !important;
      }

      &:first-child {
        margin-top: 15px;
      }

      & h3 {
        font-size: 20px;
        line-height: 24px;

        @include maxWidth($mobile-l) {
          @include h3;
        }
      }
    }

    &_link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__active {
      color: $gray-text;

      & p,
      h3,
      h4,
      a {
        color: $gray-text;
      }
    }
  }
}
