@import 'assets/styles/_mixins.scss';

.swiper {
  margin-top: 43px;

  &__slide {
    width: 206px;

    @include maxWidth($mobile-xl) {
      width: 130px;
    }
  }

  :global(.custom-pagination-bullet) {
    display: none;

    @include maxWidth($mobile-xl) {
      display: inline-block;
    }
  }
}

.card_image {
  border-radius: 22.79px;

  @include maxWidth($mobile-xl) {
    border-radius: 17.08px;
  }
}
