@import 'assets/styles/_mixins.scss';

.frame {
  width: 80dvw;
  aspect-ratio: 16/9;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.2);
  border-radius: 6px;
  z-index: 20;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;

  @include maxWidth($tablet-l) {
    width: 90dvw;
  }

  @media (min-aspect-ratio: 16/9) {
    height: 80dvh;
    width: auto;
  }

  &__video {
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }

  &--played {
    pointer-events: all;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &__close {
    width: 31px;
    height: 31px;
    position: fixed;
    right: 40px;
    top: 100px;
    z-index: 25;
    cursor: pointer;

    @include maxWidth($tablet-l) {
      top: 90px;
      width: 25px;
      height: 25px;
    }

    @include maxWidth($tablet-m) {
      right: 35px;
    }

    @include maxWidth($mobile-xl) {
      right: 25px;
    }

    @include maxWidth($mobile-l) {
      top: 90px;
      width: 20px;
      height: 20px;
    }
  }
}

.background {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  right: 0;
  background: #000000;
  opacity: 0.8;
  z-index: 15;
  cursor: default;

  &--active {
    display: block;
  }
}
