@import 'assets/styles/_mixins.scss';

.custom_field {
  position: relative;
  width: 100%;
  padding: 13px 14px 15px;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  border-radius: 10px;

  border: none;
  color: $placeholder-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  background: $black-light;
  text-align: left;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    right: 3%;
    width: 24px;
    height: 13px;
    background: url('/images/selectFieldArrow.png') 97% center / contain no-repeat, $black-light;
    transition: transform 0.3s;

    @include maxWidth($tablet-s) {
      width: 17px;
      height: 11px;
      top: calc(50% - 3px);
      background: url('/images/selectFieldArrowMobile.png') 97% center / contain no-repeat,
        $black-light;
    }
  }

  @include maxWidth($tablet-s) {
    border-radius: 8px;
    font-size: 20px;
    line-height: 140%;
  }

  @include maxWidth($mobile-xl) {
    padding: 12px 14px 14px;
    border-radius: 6px;
    font-size: 17px;
  }

  @include maxWidth($mobile-l) {
    border-radius: 4px;
    font-size: 13px;
  }

  &__open {
    &::after {
      transform: rotate(180deg);
    }
  }

  &__list {
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    border-radius: 15px;
    overflow: hidden;
    color: $placeholder-color;
    background-color: $gray-background-secondary;

    &_item {
      padding: 13px 14px 15px;
      cursor: pointer;
      background-color: $gray-background-secondary;
      transition: all 0.15s;

      &:hover {
        color: $light-gray;
      }

      &__active {
        position: relative;

        &::after {
          content: '';
          width: 10px;
          height: 10px;
          background-color: $placeholder-color;
          position: absolute;
          top: calc(50% - 5px);
          right: 4%;
          border-radius: 50%;
        }

        &:hover {
          color: $placeholder-color;
          cursor: default;
        }
      }

      @include maxWidth($tablet-s) {
        border-radius: 8px;
        font-size: 20px;
        line-height: 140%;
      }

      @include maxWidth($mobile-xl) {
        padding: 12px 14px 14px;
        border-radius: 6px;
        font-size: 17px;
      }

      @include maxWidth($mobile-l) {
        border-radius: 4px;
        font-size: 13px;
      }
    }

    @include maxWidth($mobile-xl) {
      border-radius: 8px;
    }
  }
}
