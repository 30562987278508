@import 'assets/styles/_mixins.scss';

.button {
  position: relative;
  padding: 17.8402px 19.8224px 18.8313px;
  background: $blue-secondary;
  border-radius: 8.38533px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: $white;
  transition: background-color 0.1s linear;

  &:disabled {
    // background-color: $gray-medium;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover,
  &:focus-visible {
    background: $blue;
  }

  &__text {
    &--loading {
      opacity: 0;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
  }

  &__gray {
    background-color: $gray-background;

    &:hover,
    &:focus-visible {
      background: $blue;
    }
  }

  @include maxWidth($mobile-xl) {
    padding: 3.91px 6.95px 6.08px 6.95px;
    border-radius: 3.64px;
  }
}
