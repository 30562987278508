@import './assets/styles/mixins';

.vacancy_icons {
  margin-top: 53px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include maxWidth($mobile-xl) {
    margin-top: 37.72px;
  }
}
