@import 'assets/styles/_mixins.scss';

.block {
  padding: 48px 55px;
  background: #1c1c1d;
  border-radius: 20px;

  @include maxWidth($tablet-l) {
    padding: 24px 35px;
  }

  &__actions {
    display: contents;
  }

  &--small {
    padding: 0;
    background: transparent;

    & .block__actions {
      display: flex;
      gap: 7px;
    }

    & .input {
      max-width: 225px;
      width: 100%;
      height: 46px;
      padding: 13px 7px 15px 14px;
      border-radius: 10px;
      margin: 0;
      background: #222222;

      @include p2;

      @include maxWidth($mobile-xl) {
        max-width: 202px;
        height: 44px;
        padding: 13px 7px 15px 14px;
        border-radius: 4px;
        font-size: 13px;
      }
    }
  }

  &__privacy {
    display: flex;
    gap: 11px;
    margin-top: 14px;

    &__descr {
      color: $gray-text;

      & a {
        margin: 0 4px;
        text-decoration: underline;
      }

      &--small {
        font-size: 16px;
      }
    }
  }
}

.input {
  @include p1;
  padding: 13px 7px 15px 18px;
  width: 100%;
  height: 66px;
  border-radius: 10px;
  background: #2f2f2f;
  margin: 24px 0;
  color: $white;

  @include maxWidth($mobile-xl) {
    height: unset;
  }
}

.button {
  width: 100%;
  background: $blue-secondary;
  transition: 0.1s;
  background: #0041ff;

  & p {
    @include h4;
    text-transform: uppercase;
  }

  &:hover {
    background: $blue;
  }

  &--small {
    width: 132px;
    height: 46px;
    padding: 7px 16px 8px 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    & p {
      font-size: 21px;
      font-weight: 400 !important;
      line-height: 26.34px;
      text-transform: none;
    }

    @include maxWidth($mobile-xl) {
      width: 114px;
      height: 44px;
      padding: 7px 16px 8px 16px !important;
      border-radius: 4px !important;

      & p {
        font-size: 15px;
        font-weight: 400;
        line-height: 26.34px;
      }
    }
  }
}

.title {
  @include minWidth($desktop-s) {
    font-size: 60px;
    line-height: 54px;
  }

  &_small {
    font-size: 21px;
    font-weight: 500;
    line-height: 21px;
    color: #5b5b5b;
    margin-bottom: 21px;

    @include maxWidth($mobile-xl) {
      font-size: 15px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 14px;
    }
  }
}
