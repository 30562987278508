@import './assets/styles/mixins';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $blue-4;

  // backdrop-filter: blur(6px);

  canvas {
    width: 100%;
    height: 100%;
  }

  &__blur_hash {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    visibility: visible;
    transition: visibility 0.3s, opacity 0.3s;

    &--hidden {
      opacity: 0;
      visibility: hidden;
      animation: disappearing 0.3s;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  opacity: 0;

  &--loaded {
    opacity: 1;
  }
}

@keyframes disappearing {
  to {
    display: none;
  }
}
