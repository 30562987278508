@import './assets/styles/mixins';

.text {
  margin: 46px 0 27px;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: $gray-medium;
  max-width: 808px;

  @include maxWidth($tablet-l) {
    margin: 32px 0 20px;
    font-size: 20px;
  }

  &:last-child {
    margin: 0;
  }

  @include maxWidth($mobile-xl) {
    margin: 25px 0 15px;
    font-size: 13px;
    line-height: 140%;
  }

  &_container {
    position: relative;
    margin-top: 180px;
    max-width: 904px;
    text-align: left;
    max-height: 342px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 227px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) -19.38%, #000000 70.45%);

      @include maxWidth($mobile-xl) {
        height: 108px;
      }
    }

    @include maxWidth($tablet-l) {
      margin-top: 130px;
    }

    @include maxWidth($mobile-xl) {
      margin-top: 47.65px;
    }

    &__button {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      text-transform: none;
      display: block;
      cursor: pointer;
      margin-top: 15px;
      text-align: center;

      @include maxWidth($mobile-xl) {
        left: 0;
        transform: translateX(0);
      }
    }

    &--collapsed {
      max-height: unset;

      & button {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}

.text_container {
  .toggle_checkbox {
    display: none;
  }

  .text_wrapper {
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
  }

  .toggle_checkbox:checked ~ .text_wrapper {
    max-height: none;
    overflow: visible;

    &:after {
      display: none;
    }
  }
}
