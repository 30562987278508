@import './assets/styles/mixins';

.partners {
  margin: 176px auto 105px;
  max-width: 100%;

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
      object-fit: contain;
      height: 100%;
    }
  }

  &_title {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.03em;
    color: $gray-text;

    @include maxWidth($tablet-l) {
      font-size: 30px;
    }

    @include maxWidth($mobile-l) {
      font-size: 18px;
    }
  }

  @include maxWidth($tablet-l) {
    margin: 120px auto 81px;
  }

  @include maxWidth($mobile-xl) {
    margin: 95px auto 75px;
  }

  &_list {
    overflow: hidden;
    max-width: 1540px;
    gap: 60px;

    :global(.marquee) {
      padding-left: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 60px;
      margin-top: 24px;

      @include maxWidth($mobile-xl) {
        gap: 38px;
      }
    }

    &__item {
      display: block;
      position: relative;
      width: 110px;
      height: 61px;
      cursor: pointer;

      @include maxWidth($tablet-m) {
        width: 90px;
      }

      @include maxWidth($mobile-xl) {
        width: 70px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
