@import './assets/styles/_mixins.scss';

.all_games {
  width: 100%;

  &__list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;

    @include maxWidth($desktop-s) {
      gap: 30px;
    }

    &_item {
      width: 100%;
      max-width: 271.46px;

      @include maxWidth($desktop-m) {
        max-width: 255px;
      }

      @include maxWidth($desktop-s) {
        width: calc(25% - 30px);
      }

      @include maxWidth($tablet-l) {
        width: calc(25% - 18px);
      }

      @include maxWidth($tablet-m) {
        width: calc(33% - 15px);
      }

      @include maxWidth($mobile-xl) {
        width: calc(50% - 12px);
      }
    }
  }
}
