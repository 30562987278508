@import 'assets/styles/_mixins.scss';

.contact_form {
  &__title {
    margin: 60px 0 40px;

    @include maxWidth($mobile-xl) {
      margin: 36px 0 25px;
    }
  }

  &__submit {
    @include maxWidth($tablet-s) {
      font-size: 26px;
    }

    @include maxWidth($mobile-xl) {
      font-size: 21px;
      line-height: 19px;
    }

    @include maxWidth($mobile-l) {
      padding: 11.9533px 13.2815px 12.6174px;
      width: 100%;
      border-radius: 5.61837px;
    }
  }
}
