@import 'assets/styles/_mixins.scss';
@import 'assets/styles/vars/_scss-variables.scss';

.viewAll {
  margin-top: 60px;
  display: flex;
  justify-content: center;

  &_button {
    padding: 17.8402px 19.8224px 18.8313px;
    border-radius: 8.3px;
    background: $secondary-black;
    font-weight: 700;
    font-size: 35.1464px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    transition: 0.1s;

    &:hover {
      background: $blue;
    }

    @include maxWidth($mobile-xl) {
      padding: 11.6857px 12.9842px 12.335px;
      font-weight: 700;
      font-size: 21px;
      line-height: 19px;
      border-radius: 5.4926px;
    }
  }

  @include maxWidth($mobile-xl) {
    margin-top: 49px;
  }
}
