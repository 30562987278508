@import './assets/styles/vars/_scss-variables.scss';

.nav_item {
  position: relative;
  cursor: pointer;
  transition: color 0.1s;

  &:hover {
    color: $gray-text;

    & ul {
      transform: scaleY(1);
    }
  }

  &__active {
    color: $gray-text;
  }
}
