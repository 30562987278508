@import 'assets/styles/_mixins.scss';

.games {
  display: flex;
  flex-direction: column;
  gap: 49px;
  margin-top: 90px;
  max-width: 100%;
  max-width: $common-layout-width;

  @include maxWidth($desktop-m) {
    margin-top: 80px;
  }

  @include maxWidth($tablet-l) {
    margin-top: 70px;
  }

  @include maxWidth($tablet-m) {
    margin-top: 60px;
  }

  &:first-child {
    margin-top: 0;
  }

  @include maxWidth(450) {
    margin-top: 40px;
    gap: 35px;
  }

  &__swiper {
    &_slide {
      @include minWidth($desktop-l) {
        max-width: max-content;
      }

      @include maxWidth($mobile-m) {
        max-width: 130px;
      }
    }
  }
}
