@import 'assets/styles/_mixins.scss';
@import 'assets/styles/vars/_scss-variables.scss';

.game {
  &_item {
    position: relative;
    list-style: none;
    pointer-events: none;

    &--pointer {
      cursor: pointer;
      pointer-events: all;
    }

    @include minWidth($tablet-l) {
      &:hover {
        .game_item__button {
          pointer-events: all;
          opacity: 1;
          transform: translate(-50%, -100%);
        }

        .game_item_content {
          transform: translateY(-3px);
        }
      }
    }

    &__button {
      pointer-events: none;
      opacity: 0;
      transform: translate(-50%, -20%);
      transition: 0.3s ease;
      position: absolute;
      bottom: 0;
      left: 50%;
      white-space: nowrap;
      width: 124.64px;
      height: 66.67px;
      padding: 0;

      & p {
        font-size: 35px;
      }
    }

    &_image {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      overflow: hidden;
      transition: 0.26s;
      transform: scale(0.97);
      // width: 280px;
      width: 100%;
      aspect-ratio: 1;

      &:hover {
        transform: scale(1);
      }

      @include maxWidth($desktop-l) {
        height: 100%;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }

      @include maxWidth(900) {
        border-radius: 17px;
      }

      &_container {
        position: absolute !important;
        inset: 0 !important;
      }

      &__blured {
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          z-index: 1;
        }
      }
    }

    &_commingSoon {
      position: absolute;
      z-index: 2;
      color: black;
      text-align: center;
      border-radius: 100px;
      left: 50%;
      bottom: 5%;
      transform: translate(-50%, 0);
      background-color: $white;
      width: 90%;
      padding: 10px 5px;
      font-weight: 700;
      font-size: 26px;
      text-transform: uppercase;
      letter-spacing: -0.03em;
      @include maxWidth($desktop-s) {
        font-size: 20px;
      }
      @include maxWidth($tablet-s) {
        font-size: 16px;
      }
      @include maxWidth($mobile-l) {
        font-size: 13px;
      }
    }
    &_inProgress {
      position: absolute;
      z-index: 2;
      color: black;
      border-radius: 100px;
      right: 3%;
      top: 3%;
      background-color: $black;
      color: $white;
      padding: 3px 16px 6px;
      font-weight: 700;
      font-size: 26px;
      letter-spacing: -0.03em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include maxWidth($desktop-s) {
        font-size: 20px;
      }
      @include maxWidth($tablet-s) {
        font-size: 16px;
      }
      @include maxWidth($mobile-l) {
        font-size: 13px;
      }
    }

    &_content {
      max-width: 100%;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 5px;
      transition: transform 0.2s linear;

      @include maxWidth($mobile-xl) {
        margin-left: 5px;
        margin-top: 14px;
      }
    }

    &_rating {
      display: flex;
      align-items: center;
      gap: 4px;

      @include maxWidth($mobile-xl) {
        height: 18px;
      }

      &_number {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: $gray-text;

        @include maxWidth($mobile-xl) {
          font-size: 11.1047px;
          line-height: 100%;
        }
      }
    }

    &_title {
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;

      &_withoutRating {
        margin-top: 9px;
      }

      @include maxWidth(900) {
        font-size: 13px;
        line-height: 15px;
      }

      @include maxWidth($mobile-xl) {
        margin-top: -5px;
      }
    }

    &__extra_info {
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      color: $gray-text;

      @include maxWidth(900) {
        font-size: 11px;
        line-height: 100%;
      }

      @include maxWidth($mobile-xl) {
        margin-top: -5px;
      }
    }
  }
}
