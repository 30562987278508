@import 'assets/styles/_mixins.scss';

.slotMachines {
  margin-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include maxWidth($tablet-l) {
    margin-top: 100px;
  }
  &__titleBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  &__tag360 {
    padding: 5px 5px 1px;

    background: $white;
    border-radius: 42.6902px;
    svg {
      path {
        fill: $blue-dark;
      }
    }

    @include maxWidth(450) {
      width: 50px;
      height: 30px;
    }
  }
  // &__title {
  //   font-weight: 700;
  //   font-size: 90px;
  //   line-height: 80%;
  //   text-align: center;
  //   letter-spacing: -0.03em;

  //   @include maxWidth(900) {
  //     font-weight: 700;
  //     font-size: 48px;
  //     line-height: 80%;
  //   }

  //   @include maxWidth(450) {
  //     font-size: 36px;
  //     line-height: 29px;
  //   }
  // }
  &__subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    max-width: 750px;
    text-align: center;
    margin-top: 25px;
    display: flex;
    gap: 12px;
    align-items: center;
    @include maxWidth($tablet-s) {
      font-size: 18px;
      line-height: 23px;
      margin-top: 13px;
    }
  }

  &__list {
    width: 100%;
    height: fit-content;
  }
}

// .banner {
//   position: relative;
//   height: 551px;
//   margin-top: 111px;
//   display: flex;
//   padding: 53px 85px;
//   background: linear-gradient(180deg, #1c082c 0%, #0400b8 100%);
//   border-radius: 30px;
//   overflow: hidden;

//   @include maxWidth($tablet-l) {
//     height: 880px;
//     padding: 33px 50px;
//   }

//   @include maxWidth($tablet-m) {
//     height: 720px;
//     padding: 25px 30px;
//   }

//   @include maxWidth($mobile-xl) {
//     height: 600px;
//     padding: 22px 25px;
//   }

//   @include maxWidth($mobile-m) {
//     height: 549px;
//     padding: 25px 30px;
//   }

//   @include maxWidth($mobile-m) {
//     margin-top: 64px;
//   }

//   &__left {
//     max-width: 395px;
//     display: flex;
//     flex-direction: column;

//     @include maxWidth($tablet-l) {
//       max-width: unset;
//     }
//   }

//   &__right {
//     width: 1239px;
//     height: 570px;
//     position: absolute;
//     top: -15px;
//     left: 300px;
//     pointer-events: none;

//     @include maxWidth($tablet-l) {
//       top: unset;
//       bottom: 0;
//       left: 50%;
//       transform: translateX(-57%);
//     }

//     @include maxWidth($tablet-m) {
//       width: 939px;
//       height: 460px;
//     }

//     @include maxWidth($mobile-xl) {
//       width: 679px;
//       height: 353px;
//     }

//     @include maxWidth($mobile-m) {
//       display: none;
//     }

//     &_mobile {
//       display: none;

//       @include maxWidth($mobile-m) {
//         position: absolute;
//         bottom: 0;
//         left: 50%;
//         transform: translateX(-50%);
//         display: block;
//         height: 259px;
//         width: 380px;
//       }
//     }
//   }

//   &_title {
//     font-weight: 700;
//     font-size: 74px;
//     line-height: 80%;
//     letter-spacing: -0.03em;

//     @include maxWidth($tablet-m) {
//       font-size: 50px;
//     }

//     @include maxWidth($mobile-xl) {
//       font-size: 42px;
//     }

//     @include maxWidth($mobile-l) {
//       font-size: 36px;
//     }
//   }

//   &_text {
//     margin-top: 35px;
//     font-style: normal;
//     font-weight: 400;
//     font-size: 24px;
//     line-height: 130%;
//     opacity: 0.7;

//     @include maxWidth($tablet-m) {
//       font-size: 20px;
//     }

//     @include maxWidth($mobile-xl) {
//       margin-top: 20px;
//       font-size: 18px;
//     }
//   }

//   &_tag {
//     cursor: pointer;
//     margin-top: 110px;
//     padding-bottom: 65px;
//     display: flex;
//     align-items: center;
//     gap: 12px;
//     font-weight: 400;
//     font-size: 24px;
//     line-height: 130%;
//     transition: 0.15s;

//     &_icon {
//       width: 76px;
//       height: 46px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       background: $white;
//       border-radius: 57.5556px;
//       backface-visibility: visible;
//       transform-style: preserve-3d;
//       perspective: 200px;
//       transition: transform 2s;

//       @include maxWidth($tablet-l) {
//         width: 44.3px;
//         height: 26.64px;
//       }

//       svg {
//         @include maxWidth($tablet-l) {
//           width: 34.3px;
//           height: 17.64px;
//         }
//       }
//     }

//     &:hover &_icon {
//       transform: rotateY(360deg);
//     }

//     @include maxWidth($tablet-l) {
//       margin-top: 27px;
//       font-weight: 400;
//       font-size: 14.3046px;
//       line-height: 130%;
//       padding-bottom: 40px;
//     }

//     @include maxWidth($mobile-l) {
//       margin-top: 27px;
//     }
//   }

//   @include maxWidth($tablet-l) {
//     flex-direction: column;
//   }

//   @include maxWidth($mobile-l) {
//     padding: 24px 18px 24px 28px;
//     border-radius: 20px;
//   }
// }
