@import './assets/styles/mixins';

.machine_downloads {
  width: 100%;
  padding-left: 50px;
  margin-top: 73px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  @include maxWidth($tablet-l) {
    padding-left: 0;
  }

  @include maxWidth($mobile-xl) {
    margin-top: 38px;
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 9.71px;
  }

  &__item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13.6329px 15.1476px 14.3903px;
    background: $secondary-black;
    font-size: 26.8577px;
    font-weight: 700;
    border-radius: 4.39161px;
    text-transform: uppercase;

    & a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      right: 0;
    }

    @include maxWidth($mobile-xl) {
      font-size: 18.407px;
      line-height: 15px;
    }
  }

  &__title {
    margin-bottom: 18px;

    @include maxWidth($mobile-xl) {
      margin-bottom: 8px;
    }
  }

  &__descr {
    margin-bottom: 29px;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: $gray-text;

    @include maxWidth($mobile-xl) {
      margin-bottom: 16px;
      font-size: 11px;
    }
  }
}
