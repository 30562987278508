@import './assets/styles/_mixins.scss';

.games_list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  gap: 44.54px;

  @include maxWidth($desktop-s) {
    gap: 38px;
  }

  @include maxWidth($tablet-l) {
    gap: 24px;
  }
}
