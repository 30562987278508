@import './assets/styles/mixins';

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.p1 {
  @include p1;
}

.p2 {
  @include p2;
}
