@import './assets/styles/mixins';

.social_links_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;

  &__links {
    display: flex;
    gap: 14px;

    @include maxWidth($mobile-xl) {
      justify-content: flex-start;
      flex-flow: row wrap;
      gap: 10px;

      &_link {
        width: 41.65px;
        height: 41.65px;
      }
    }
  }

  &__link {
    min-width: 43px;

    svg rect {
      transition: 0.16s;
      fill: $gray-background;
    }

    &:hover {
      svg rect {
        fill: $blue;
      }
    }

    &__opposite_color {
      svg rect {
        fill: $blue;
      }

      &:hover {
        svg rect {
          fill: $gray-background;
        }
      }
    }
  }
}
