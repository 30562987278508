@import 'assets/styles/_mixins.scss';

.compare_table {
  margin-top: 82px;
  display: flex;
  flex-flow: column nowrap;
  max-width: 1618px;
  gap: 5px;

  &__machine {
    &_name {
      margin-top: 50px;
      color: $white;
    }

    &_descr {
      margin-top: 11px;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: $placeholder-color;
    }
  }

  &__row {
    padding: 10px 27px 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    text-align: start;
    border-radius: 4px;

    @include maxWidth($desktop-s) {
      padding: 8px 27px 8px;
      gap: 32px;
    }

    &:hover {
      background-color: $black-light;
    }

    &_item {
      display: flex;
      flex-direction: column;
      font-family: 'Monument Grotesk';
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      color: $placeholder-color;
      width: 100%;

      @include maxWidth($desktop-s) {
        font-size: 22px;
      }

      & span {
        color: $white;
      }

      &:first-child {
        flex-shrink: 0;
        max-width: 202px;
        color: $gray-text;
        max-width: calc(16% - 41px);

        @include maxWidth($desktop-s) {
          max-width: calc(16% - 31px);
        }
      }

      &:nth-child(2) {
        // max-width: 360px;
        max-width: calc(28% - 41px);

        @include maxWidth($desktop-s) {
          max-width: calc(28% - 31px);
        }
      }

      &:nth-child(3) {
        // max-width: 344px;
        max-width: calc(28% - 41px);

        @include maxWidth($desktop-s) {
          max-width: calc(28% - 31px);
        }
      }

      &:last-child {
        // max-width: 427px;
        max-width: calc(28% - 41px);

        @include maxWidth($desktop-s) {
          max-width: calc(28% - 31px);
        }
      }
    }
  }

  &__header {
    margin-bottom: 31px;
    padding: 10px 27px 10px;
    gap: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;

    @include maxWidth($desktop-s) {
      padding: 8px 27px 8px;
      gap: 32px;
    }
  }

  &__bottom {
    margin-top: 26px;
    padding: 10px 27px 10px;
    gap: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;

    @include maxWidth($desktop-s) {
      padding: 8px 27px 8px;
      gap: 32px;
    }

    &_title {
      margin-bottom: 18px;
      color: $white;
    }

    &_link {
      width: fit-content;
      margin-top: 44px;
      padding: 7px 16px 12px;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: $white;
      background-color: $black-light;
      border-radius: 10px;
      transition: opacity 0.12s ease;
      text-transform: capitalize;

      &:hover {
        opacity: 0.7;
      }
    }

    &_rating {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 24px;
      line-height: 26px;
      color: $gray-text;
    }
  }
}
