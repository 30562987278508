@import './assets/styles/mixins';

.categories_list {
  max-width: 100%;
  margin-top: 55px;
  display: flex;
  justify-content: center;
  gap: 15px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
    height: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @include maxWidth($desktop-s) {
    justify-content: flex-start;
    margin-top: 38px;
    padding-left: 40px;
  }

  @include maxWidth($tablet-m) {
    padding-left: 35px;
  }

  @include maxWidth($mobile-xl) {
    gap: 12px;
    padding-left: 24px;
  }

  @include maxWidth($mobile-m) {
    gap: 8px;
  }
}
