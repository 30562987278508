@import './assets/styles/mixins';

.wrap {
  // overflow-x: hidden;
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100vh;
  padding-top: 82px;

  @include maxWidth($tablet-l) {
    padding-top: 62px;
  }
}

.content {
  position: relative;
  flex: 1 1 auto;

  @include maxWidth($tablet-l) {
  }
}
