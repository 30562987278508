@import './assets/styles/mixins';

.games_section {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__list {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    gap: 45px;
    align-items: flex-start;
    justify-content: flex-start;

    @include maxWidth($desktop-s) {
      gap: 35px;
    }

    @include maxWidth($tablet-l) {
      gap: 30px;
    }

    @include maxWidth($mobile-xl) {
      gap: 36px;
    }

    @include maxWidth($mobile-l) {
      gap: 20px;
    }

    &_item {
      width: calc(20% - 36px);

      @include maxWidth($desktop-s) {
        width: calc(25% - 26.25px);
      }

      @include maxWidth($tablet-l) {
        width: calc(33% - 20px);
      }

      @include maxWidth($tablet-s) {
        max-width: unset;
        width: calc(33% - 20px);
      }

      @include maxWidth($mobile-xl) {
        width: calc(50% - 18px);
      }

      @include maxWidth($mobile-l) {
        width: calc(50% - 10px);
      }
    }
  }

  &__pagination {
    margin-top: 100px;
  }

  @include maxWidth($tablet-l) {
    gap: 30px;
  }

  @include maxWidth(340) {
    gap: 7vw;
  }
}
