@import './assets/styles/mixins';

.employeePage {
  font-family: $font-main;

  &__inner {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.49) 62.88%,
      rgba(3, 1, 130, 0.6) 89.58%,
      rgba(0, 0, 0, 0) 100%
    );
    padding-bottom: 150px;

    @include maxWidth($mobile-xl) {
      padding-bottom: 70px;
    }
  }

  &__employeeContainer {
    max-width: 1050px;
    margin-top: 30px;
  }

  &__employeesContainer {
    margin-top: 71px;
    max-width: 835px;

    @include maxWidth($mobile-xl) {
      margin-top: 40px;
    }
  }

  &__title {
    font-family: $font-main;
    font-weight: 700;
    font-size: 90px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.03em;
    margin-bottom: 40px;

    @include maxWidth($mobile-xl) {
      font-size: 48px;
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.03em;
    margin-bottom: 70px;

    @include maxWidth($mobile-xl) {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;

    @include maxWidth($tablet-m) {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }
  }

  &__photo {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    background-color: $gray-background;
    border-radius: 22px;
    aspect-ratio: 1/1;
  }

  &__footActions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    font-size: 24px;

    a {
      display: flex;
      gap: 14px;
      align-items: center;
    }

    @include maxWidth($mobile-xl) {
      font-size: 13px;
      margin-top: 50px;
    }
  }
}

.info {
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;

  @include maxWidth($mobile-xl) {
    font-size: 13px;
    line-height: 140%;
  }

  &__text {
    margin-bottom: 30px;

    @include maxWidth($mobile-xl) {
      margin-bottom: 20px;
    }
  }

  &__tags {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 45px;

    @include maxWidth($mobile-xl) {
      margin-bottom: 32px;
    }
  }

  &__socials {
    display: flex;
    gap: 15px;
    flex-flow: row wrap-reverse;
    margin-bottom: 65px;

    a {
      width: 43px;
      height: 43px;
    }

    @include maxWidth($mobile-xl) {
      font-size: 16px;
      gap: 30px 15px;
      margin-bottom: 40px;
    }
  }

  &__actions {
    button {
      @include maxWidth($mobile-xl) {
        font-size: 21px;
        width: 100%;
      }
    }
  }
}

.tag {
  padding: 9px 16px 14px;
  border-radius: 8px;
  background-color: $black-light;

  @include maxWidth($mobile-xl) {
    padding: 4px 11.5px 9px;
  }
}

.skypeLayout {
  width: 43px;
  height: 43px;
  border-radius: 7.52021px;
  background-color: $blue-secondary-ligth;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-left: 10px;
  height: 100%;
  span {
    word-wrap: break-word;
  }

  &_link {
    width: fit-content !important;
  }
}
