@import './assets/styles/mixins';

.login_page {
  &__content {
    max-width: 585px;
    text-align: center;
    margin: 79px auto 0;
  }

  &__title {
    margin-bottom: 66px;

    @include maxWidth($mobile-xl) {
      @include h1;
      margin-bottom: 45px;
    }
  }

  &__privacy {
    margin: 24px 0 61px;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: $gray-text;

    @include maxWidth($tablet-m) {
      font-size: 16px;
    }

    @include maxWidth($mobile-l) {
      margin: 25.43px 0 36px;
      font-size: 11.3441px;
      text-align: start;
    }
  }

  &__already_have {
    &__link {
      margin-left: 6px;
      color: $blue;
    }
  }
}
