@import 'assets/styles/_mixins.scss';

.tab {
  position: relative;
  border-radius: 10px;

  &::after {
    display: block;
    content: '';
    background-color: $blue;
    height: 2px;
    width: 0%;
    bottom: 0;
    left: 50%;
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: translateX(-50%);
  }

  &:hover {
    &::after {
      width: 90%;
    }
  }

  @include maxWidth($tablet-l) {
    background-color: $black-light;

    &:hover {
      &::after {
        display: none;
      }
    }
  }

  @include maxWidth($mobile-xl) {
    border-radius: 6.5px;
  }

  &__active {
    background: $blue;

    &:hover::after {
      display: none !important;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    padding: 7px 16px 12px;
    white-space: nowrap;

    @include maxWidth($tablet-l) {
      font-size: 20px;
      line-height: 22px;
    }

    @include maxWidth($tablet-s) {
      font-size: 18px;
      line-height: 20px;
    }

    @include maxWidth($mobile-xl) {
      padding: 5.59px 13.49px 7.87px;
      font-size: 16px;
      line-height: 17px;
    }

    @include maxWidth($mobile-m) {
      font-size: 13px;
    }
  }
}
