@import './assets/styles/vars/_scss-variables.scss';

.nav_droplist {
  width: max-content;
  position: absolute;
  z-index: 250;
  gap: 13px;
  padding: 20px 40px 30px 20px;
  border-radius: 20px;
  background: $black-light;
  backdrop-filter: blur(10px);
  background: RGBA(34, 34, 34, 0.8);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;

  &__item {
    color: $white;
    cursor: pointer;

    &:hover {
      color: $gray-text;
    }

    &__active {
      color: $gray-text;
    }
  }
}
