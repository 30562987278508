@import './assets/styles/mixins';

.roadmap {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 70px;

  @include maxWidth($tablet-l) {
    gap: 0;
  }

  @include maxWidth($mobile-xl) {
    padding-right: 0;
  }

  &__mobile {
    // &_games_list {
    //     gap: 0;
    // }
  }

  &__games {
    max-width: 100vw;

    &_list {
      justify-content: flex-start;
      gap: 45px;

      &_item {
        // gap: 0 !important;
        width: calc(25% - 33.75px);
      }

      &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }
}

.desktop {
  @include maxWidth($mobile-xl) {
    display: none;
  }
}

.mobile {
  display: none;

  @include maxWidth($mobile-xl) {
    display: block;
  }
}
