@import './assets/styles/_mixins.scss';

.char_list {
  width: 100%;
  margin-top: 111px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 30px;

  @include maxWidth($tablet-m) {
    margin-top: 75px;
  }

  @include maxWidth($tablet-s) {
    flex-flow: row wrap;
    gap: 40px;
  }

  @include maxWidth($mobile-l) {
    margin-top: 32px;
    gap: 14px;
  }

  &__item {
    display: flex;
    width: calc(25% - 22.5px);
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;

    @include maxWidth($tablet-s) {
      min-width: unset;
      max-width: unset;
      width: calc(50% - 20px);
    }

    @include maxWidth($mobile-l) {
      width: calc(50% - 7px);
    }

    &_title {
      opacity: 0.7;
    }

    &_value {
      @include maxWidth($tablet-s) {
        @include h2;
      }
    }
  }
}
