@import './assets/styles/mixins';

.bookAMeetForm {
  width: 100%;
  margin: 50px 0;
  background: #151515;
  border-radius: 17px;
  padding: 45px 40px 75px;
  @include maxWidth($tablet-l) {
    padding: 25px 25px 35px;
  }
  &__title {
    text-align: center;
    font-family: $font-main;
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.03em;
    margin-bottom: 34px;
    @include maxWidth($tablet-l) {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 15px;
    }
  }
  &__subtitle {
    color: $placeholder-color;
    text-align: center;
    font-family: $font-main;
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.03em;
    @include maxWidth($tablet-l) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  &__fields {
    display: grid;
    gap: 13px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'a b' 'c c';
    label {
      &:nth-child(1) {
        grid-area: a;
      }
      &:nth-child(2) {
        grid-area: b;
      }
      &:nth-child(3) {
        grid-area: c;
      }
    }
    input,
    textarea {
      color: $placeholder-color;
      background-color: $white;
    }
    textarea {
      max-height: 140px;
    }

    @include maxWidth($tablet-l) {
      flex-direction: column;
    }
  }
  form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 13px;

    button {
      width: fit-content;
      margin: 0;
      line-height: 65%;
    }
    @include maxWidth($tablet-l) {
      button {
        line-height: 55%;
        font-size: 25px;
      }
    }
    @include maxWidth($tablet-s) {
      grid-template-columns: 1fr;
      button {
        width: 100%;
      }
      textarea {
        max-height: 80px;
      }
    }
  }
}
