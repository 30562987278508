.card_skeleton {
  position: absolute;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(6px);
    z-index: 100000000;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: -25%;
      left: 0;
      height: 150%;
      width: 10%;
      background: rgba(167, 191, 210, 0.2);
      box-shadow: 4px 4px 40px 60px rgb(167 191 210 / 20%);
      opacity: 0.4;
      transform: rotate(10deg);
      animation: shimmer 1s infinite linear;
    }
  }
}

@keyframes shimmer {
  0% {
    left: -25%;
  }

  100% {
    left: 125%;
  }
}
