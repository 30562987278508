.rotatorContainer {
  user-select: none;
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
  }

  &__draggable {
    cursor: grab;
  }
}

.viewBox {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000000;
}

.skeletonImage {
  position: relative;
  width: 100%;

  img {
    min-width: auto !important;
    width: fit-content !important;
  }
}
