@import 'assets/styles/_mixins.scss';

.login_form {
  display: flex;
  flex-direction: column;
  gap: 70px;

  @include maxWidth($mobile-xl) {
    gap: 32px;
  }

  &__button {
    margin: 0;
    padding: 17.8402px 62px 18.8313px;
    width: 100%;

    @include maxWidth($tablet-s) {
      font-size: 26px;
    }

    @include maxWidth($mobile-xl) {
      font-size: 21px;
      line-height: 19px;
    }

    @include maxWidth($mobile-l) {
      padding: 11.9533px 13.2815px 12.6174px;
      width: 100%;
      border-radius: 5.61837px;
    }
  }
}
