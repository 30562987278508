@import './assets/styles/mixins';

.footer {
  display: grid;
  // grid-template-columns: 3.3fr 1.2fr 0.8fr;
  grid-template-columns: auto 364px;
  gap: 104px;
  padding-bottom: 44px;

  @include maxWidth(1550) {
    gap: 40px;
    padding-left: 5px;
  }

  @include maxWidth(1440) {
    grid-template-columns: 3.3fr 1.2fr;
  }

  @include maxWidth(1260) {
    grid-template-columns: 1fr;
    gap: 29px;
  }

  @include maxWidth($mobile-m) {
    // display: block;
    padding: 0 6px 35px;
  }

  &__links {
    display: flex;
    gap: 14px;

    &_container {
      @include maxWidth($mobile-m) {
        margin: 48px auto 40px;
      }
    }

    &_link {
      min-width: 43px;

      svg rect {
        transition: 0.16s;
      }

      &:hover {
        svg rect {
          fill: #0041ff;
        }
      }
    }

    @include maxWidth($mobile-xl) {
      justify-content: center;
      gap: 10px;

      &_link {
        width: 41.65px;
        height: 41.65px;
      }
    }
  }
}

.left {
  @include maxWidth($tablet-l) {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }

  &_navigation {
    display: flex;
    flex-flow: row wrap;
    gap: 25px;
    font-weight: 400;
    font-size: 21px;
    line-height: 100%;
    color: $gray-text;

    @include maxWidth($tablet-l) {
      flex-direction: column;
    }

    @include maxWidth($mobile-xl) {
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
      gap: 15px;
    }

    &_item {
      white-space: nowrap;
      transition: 0.15s;
      letter-spacing: 0.003em;
      color: $white;
    }

    &_item:hover {
      color: $gray-text;
    }
  }

  &_content {
    margin-top: 34px;
    display: flex;
    gap: 40px;

    &_privacy {
      display: flex;
      flex-direction: column;
      margin: -5px 61px 0 0;
      white-space: nowrap;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: $gray-text;

      &_item {
        transition: 0.15s;
        color: $white;
      }

      &_item:hover {
        color: $gray-text;
      }

      @include maxWidth($mobile-xl) {
        margin: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 3px;
      }
    }

    &_age {
      min-width: 62px;

      @include maxWidth($mobile-xl) {
        min-width: 0;
        max-width: 30px;
        margin-top: -15px;
      }
    }

    &_info {
      max-width: 352.5px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: $gray-text;

      @include maxWidth($mobile-xl) {
        display: none;
      }

      &_mobile {
        display: none;

        @include maxWidth($mobile-xl) {
          display: flex;
          flex-direction: column;
          max-width: 145px;
        }
      }
    }

    @include maxWidth($tablet-l) {
      margin-top: 0;
      flex-direction: column;
      gap: 14px;
    }
  }
}

.middle {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 100%;
  color: $gray-text;

  @include maxWidth($mobile-xl) {
    gap: 5px;
  }

  &_support {
    @include maxWidth($mobile-xl) {
      font-size: 15px;
    }
  }

  &_text {
    @include maxWidth($mobile-xl) {
      font-size: 13px;
    }
  }
}

.copywriter {
  display: none;

  @include maxWidth($mobile-xl) {
    margin-top: 8px;
    margin-left: -3px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 400;
    font-size: 13px;
    line-height: 110%;
    color: $gray-text;
  }

  @include maxWidth($mobile-m) {
    font-size: 11px;
  }
}

.right_block {
  display: flex;
  flex-direction: column;
  gap: 43px;
}
