@import './assets/styles/mixins';

.list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 29px 0 20px;

  &__item {
    & > a {
      @include minWidth($tablet-l) {
        &:hover,
        &:focus-visible {
          color: $blue;
        }
      }

      position: relative;
      padding: 0 12px;
      color: rgba(255, 255, 255, 0.5);
      line-height: 140%;
      transition: color ease 0.3s;

      &::before {
        content: '';
        position: absolute;
        top: 60%;
        left: 0;
        width: 7px;
        height: 7px;
        border: 1.5px solid rgba(255, 255, 255, 0.5);
        border-bottom: none;
        border-left: none;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      // &::before {
      //   content: '';
      //   position: absolute;
      //   top: 50%;
      //   left: 0;
      //   width: 1px;
      //   height: 80%;
      //   background-color: $gray-2;
      //   transform: translateY(-50%);
      // }
    }

    &--active > a {
      // color: $blue;
      // font-weight: 500;
      pointer-events: none;
    }

    &:first-child > a {
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    &:last-child > a {
      padding-right: 0;
    }

    &--disabled {
      pointer-events: none;
    }
  }
}
