@import './assets/styles/mixins';

.main_page {
  display: flex;
  flex-direction: column;

  &__categories_container {
    @include maxWidth($mobile-xl) {
      padding-right: 0;
    }
  }

  &__slider_filters {
    margin-top: 167px;
    display: flex;
    flex-direction: column;
    gap: 55px;

    @include maxWidth($mobile-xl) {
      margin-top: 85px;
    }

    &__title {
      @include h1;

      text-align: center;
    }
  }
}

.games {
  margin-left: 2px;
  margin-top: 125px;
  display: flex;
  flex-direction: column;

  &_list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
    overflow: auto hidden;
    padding-bottom: 20px;
  }

  @include maxWidth(450) {
    margin-top: 65px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-top: 75px;

  &_title {
    margin-left: 10px;
  }

  &:first-child {
    margin-top: 0;
  }

  &_california {
    margin-top: 103px;
  }

  @include maxWidth(450) {
    margin-top: 40px;
    margin-left: -10px;
    margin-right: -24px;
    gap: 30px;
  }
}
