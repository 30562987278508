@import 'assets/styles/_mixins.scss';

.links {
  display: flex;
  gap: 23px;
}

.linkWithPreview {
  position: relative;
  background: $blue-secondary-dark;
  border-radius: 15px;
  width: 67px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 3px solid transparent;
  &_active {
    border: 3px solid $white;
  }
  &_background {
    position: absolute;
    bottom: -50%;
    width: 200%;
    height: 150%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ff9f0e 0%,
      #ff1f8a 31.25%,
      rgba(53, 98, 255, 0.58) 66.67%,
      rgba(53, 98, 255, 0) 100%
    );
    opacity: 0.9;
  }

  & img {
    z-index: 1;
    height: auto;
  }
}
