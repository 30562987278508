@import 'assets/styles/_mixins.scss';

.profile_page__tabs {
  margin-bottom: 85px;

  &_container {
    @include maxWidth($tablet-s) {
      padding-right: 0;
    }
  }

  @include maxWidth($desktop-s) {
    justify-content: center;
  }

  @include maxWidth($tablet-l) {
    padding-left: 0;
  }

  @include maxWidth($tablet-s) {
    justify-content: flex-start;
  }

  @include maxWidth($mobile-xl) {
    margin-bottom: 38px;
  }
}
