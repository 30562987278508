@import 'assets/styles/_mixins.scss';

.machine {
  &_info {
    width: 100%;
    margin-top: 92.33px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;

    @include maxWidth($tablet-l) {
      flex-direction: column;
      align-items: center;
    }

    @include maxWidth($mobile-xl) {
      margin-top: 78px;
    }
    &__view {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 18px;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      &_noRotate {
        pointer-events: none;
      }
    }
    &__button360 {
      display: flex;
      gap: 12px;
      text-align: center;
      align-items: center;
      user-select: none;
      cursor: pointer;
      pointer-events: initial;

      svg {
        backface-visibility: visible;
        transform-style: preserve-3d;
        perspective: 200px;
        transition: transform 1s;
        background: $white;
        border-radius: 58px;
        padding: 5px 5px 2px;
        path {
          fill: black;
        }
      }

      &:hover {
        svg {
          transform: rotateY(360deg);
        }
      }

      &_disabled {
        pointer-events: none;
        color: $gray-text;
        cursor: initial;
        svg {
          background: $gray-text;
        }
        &:hover {
          svg {
            transform: rotateY(0deg);
          }
        }
      }
    }

    &_image {
      width: 534.72px;
      height: 683.66px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      transform: translateX(25px);

      background: radial-gradient(
        50% 50% at 50% 50%,
        #ff9f0e 0%,
        #ff1f8a 31.25%,
        rgba(53, 98, 255, 0.58) 66.67%,
        rgba(53, 98, 255, 0) 100%
      );
      opacity: 0.9;

      @include maxWidth($desktop-s) {
        width: 450px;
      }

      @include maxWidth($tablet-l) {
        transform: translateX(0);
      }
      @include maxWidth($mobile-xl) {
        width: 80%;
      }

      @include maxWidth($mobile-l) {
        width: 90%;
        height: 509px;
      }

      &__img {
        transform: translateX(20px);

        @include maxWidth($tablet-l) {
          transform: translateX(10px);
        }

        @include maxWidth($mobile-l) {
          width: 283px !important;
          height: 509px !important;
          min-width: unset !important;
          min-height: unset !important;
        }
      }

      // &__nevada {
      //   background: radial-gradient(
      //     50% 50% at 50% 50%,
      //     #ff9f0e 0%,
      //     #ff1f8a 31.25%,
      //     rgba(53, 98, 255, 0.58) 66.67%,
      //     rgba(53, 98, 255, 0) 100%
      //   );
      // }

      // &__neostar {
      //   background: radial-gradient(
      //     50% 50% at 50% 50%,
      //     #ff9f0e 0%,
      //     #1fff5e 31.25%,
      //     rgba(255, 53, 174, 0.58) 66.67%,
      //     rgba(53, 98, 255, 0) 100%
      //   );
      // }

      // &__riogrande {
      //   background: radial-gradient(
      //     50% 50% at 50% 50%,
      //     #ff9f0e 0%,
      //     rgba(169, 61, 255, 0.65) 31.25%,
      //     #ff1f8a 66.67%,
      //     rgba(255, 52, 52, 0) 100%
      //   );
      // }
    }

    &__optional {
      display: flex;
      align-items: flex-start;
      gap: 26px;

      @include maxWidth($mobile-xl) {
        max-width: 96%;
        gap: 16px;
      }

      &_text {
        color: $placeholder-color;
        line-height: 130%;
      }

      & span {
        color: $white;
      }

      &__icon {
        padding-top: 5px;
        max-width: 26px;
        max-height: 26px;
        width: 100%;
        height: 100%;

        @include maxWidth($mobile-xl) {
          padding-top: 0;
          max-width: 16px;
          max-height: 16px;
        }
      }
    }

    &__content {
      max-width: 830px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 71px;

      @include maxWidth($tablet-l) {
        max-width: unset;
        margin-top: 90px;
        align-self: flex-start;
      }

      @include maxWidth($mobile-l) {
        margin-top: 33px;
        gap: 35px;
      }

      &__block {
        @include maxWidth($mobile-xl) {
          &:first-child {
            max-width: 97%;
          }
        }

        &_title {
          padding-left: 51px;
          margin-bottom: 33px;

          @include maxWidth($tablet-l) {
            padding-left: 0;
          }

          @include maxWidth($mobile-xl) {
            margin-bottom: 21px;
          }
        }

        &_lists_wrap {
          display: flex;
          gap: 51px;

          @include maxWidth($desktop-s) {
            flex-direction: column;
          }

          @include maxWidth($tablet-l) {
            max-width: 600px;
          }

          @include maxWidth($mobile-xl) {
            gap: 15px;
          }

          &__dimensions {
            justify-content: space-between;
          }
        }

        &_list {
          display: flex;
          flex-direction: column;
          gap: 11px;

          @include maxWidth($mobile-xl) {
            gap: 15px;
          }

          &__left {
            max-width: 418px;
            width: 100%;

            @include maxWidth($desktop-s) {
              max-width: unset;
            }
          }

          &__right {
            max-width: 351px;
            width: 100%;

            @include maxWidth($desktop-s) {
              max-width: unset;
            }
          }

          &_item {
            display: flex;
            align-items: flex-start;
            gap: 19px;

            @include maxWidth($mobile-xl) {
              gap: 8px;
            }

            & p {
              line-height: 130%;
              color: $placeholder-color;
            }

            & span {
              color: $white;
            }

            &_icon {
              max-width: 32px;
              max-height: 32px;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              @include maxWidth($mobile-xl) {
                padding-top: 3px;
                max-width: 18px;
                max-height: 18px;
              }
            }
          }
        }
      }
    }

    &__bottom_block {
      max-width: 783px;
      width: 100%;

      @include maxWidth($tablet-l) {
        max-width: unset;
      }
    }
  }
}
