@import './assets/styles/mixins';

.certificatesWrapper {
  grid-column-start: 1;
  grid-column-end: 4;
  @include maxWidth(1440) {
    grid-column-end: 3;
  }

  @include maxWidth(1260) {
    grid-column-end: 2;
  }
}
.certificates {
  margin: 40px 0 55px;
  grid-auto-columns: 3;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: center;

  @include maxWidth($mobile-m) {
    margin: 30px 0 20px;
  }

  &_list {
    overflow: hidden;
    max-width: 1540px;

    &__item {
      display: block;
      position: relative;
      width: 110px;
      height: 61px;

      @include maxWidth($tablet-m) {
        width: 90px;
      }

      @include maxWidth($mobile-xl) {
        width: 70px;
      }

      &_image {
        filter: invert(100%);
        object-fit: contain;
      }
    }
  }
}
