@import 'assets/styles/_mixins.scss';

.section {
  width: 100%;
  overflow-x: auto;
  overflow-y: clip;
  padding-top: 8px;
  margin-bottom: 46px;

  @include scrollBar(0);

  @include maxWidth($mobile-xl) {
    margin-bottom: 35px;
  }
}

.list {
  display: flex;
  align-items: center;
  gap: 40px;
  width: fit-content;
  margin: 0 auto;

  @include maxWidth($mobile-xl) {
    gap: 27px;
  }

  &__item {
    @include p1;

    display: flex;
    flex-direction: column;
    gap: 20px;
    // height: 92px;
    height: max-content;
    min-width: 104px;
    width: fit-content;
    text-align: center;
    cursor: pointer;
    transition: transform 0.15s ease;

    &:hover {
      transform: translateY(-8px);
    }

    & p {
      color: rgba(255, 255, 255, 0.7);
    }

    @include maxWidth($mobile-xl) {
      min-width: 47px;
      height: max-content;
      width: max-content;
    }

    &--active {
      opacity: 1;
    }

    &--unactive {
      opacity: 0.5;
    }

    &_link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }

  &__img {
    height: 52px !important;
    width: 100%;
    position: relative;

    @include maxWidth($mobile-xl) {
      height: 42px !important;
    }

    & img {
      object-fit: contain;
    }
  }
}
