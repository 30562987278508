@import 'assets/styles/_mixins.scss';

.section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;

  &__banner {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1013px;
    max-height: 570px;
    height: auto;
    aspect-ratio: 1013 / 570;
    z-index: 0;
    align-self: flex-start;
    border-radius: 25.9425px;
    overflow: hidden;
    color: #fff;

    &:hover &_play {
      transform: translate(-50%, -50%) scale(1.3);
      opacity: 0.7;
      color: #0041ff;
    }

    @include maxWidth($tablet-l) {
      border-radius: 10px;
    }

    @include maxWidth($mobile-xl) {
      border-radius: 8.28235px;
    }

    &_play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.4;
      transition: filter 0.15s linear, transform 0.15s ease, color 0.15s ease, opacity 0.15s linear;

      @include maxWidth($tablet-s) {
        width: 65px;
        height: 65px;
      }

      @include maxWidth($mobile-xl) {
        width: 50px;
        height: 50px;
      }

      @include maxWidth($mobile-xl) {
        width: 40px;
        height: 40px;
      }
    }

    &_video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      inset: 0;
    }

    &_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      inset: 0;
    }
  }

  &__divider_line {
    bottom: -140px;
  }
}

.desktop {
  @include maxWidth($tablet-s) {
    display: none;
  }
}

.tablet {
  display: none;

  @include maxWidth($tablet-s) {
    display: block;
  }
}
