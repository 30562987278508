@import './assets/styles/vars/scss-variables';
@import './assets/styles/mixins';

.container {
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  max-width: $common-layout-width;
  margin: 0 auto;

  @include maxWidth($tablet-m) {
    padding: 0 35px;
  }

  @include maxWidth($mobile-xl) {
    padding: 0 24px;
  }
}
