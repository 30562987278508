@import './assets/styles/mixins';

.login_with_email_section {
  max-width: 709px;
  text-align: center;
  margin-top: 27vh;
  margin-bottom: 120px;

  @include maxHeight(1600) {
    margin-top: 20vh;
  }

  @include maxHeight(1047) {
    margin-top: 79px;
  }

  @include maxWidth($mobile-xl) {
    margin-top: 60px;
    max-width: 340px;
  }

  &__title {
    @include maxWidth($mobile-xl) {
      @include h1;
    }
  }

  &__descr {
    margin: 54px 0 97px;

    @include maxWidth($mobile-xl) {
      margin: 39px 0 61px;
      color: $gray-text;
    }
  }

  &__already_have {
    &__link {
      margin-left: 6px;
      color: $blue;
    }
  }
}
