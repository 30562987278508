@import 'assets/styles/_mixins.scss';

.logo {
  display: flex;
  align-items: center;

  @include noTouchHover() {
    opacity: 0.9;
  }
}

.image {
  
  @include maxWidth($mobile-xl) {
    width: 96px;
    height: 25px;
  }
}
