@import 'assets/styles/_mixins.scss';

.provider_entertainment {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: unset;
  margin: 32px auto 0;

  @include maxWidth(489) {
    padding: 0 0 0 17.42px;
  }

  @include maxWidth($mobile-s) {
    padding: 0 17.42px;
  }

  @include maxWidth(650) {
    margin-top: 26px;
  }

  &__title {
    margin-bottom: 32px;
  }

  &__subtitle {
    @include minWidth($tablet-l) {
      font-size: 36px;
    }
  }
}
