@import 'assets/styles/_mixins.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 85px;
  height: 165px;
  transition: transform 0.25s linear;

  &_container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: visible;
    z-index: 2;
    pointer-events: none;
  }

  &__item {
    height: 165px;
    min-height: 165px;
    opacity: 0;
    transition: opacity 0.25s linear;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    pointer-events: none;

    &--active {
      opacity: 1;
      // pointer-events: all;

      & button {
        pointer-events: all;
      }
    }

    &_link {
      width: fit-content;
    }

    &_text_block {
      margin-bottom: 20px;

      @include maxWidth($mobile-xl) {
        display: none;
      }

      &__title {
        @include maxWidth($mobile-xl) {
          @include h2;
        }
      }

      &__category {
        margin-top: 6px;
        opacity: 70%;
      }
    }
  }

  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18.5px 39.5px 19.5px 39.5px;

    & p {
      font-size: 35px;
      line-height: 1;
    }

    @include maxWidth($tablet-s) {
      padding: 15.67px 30px 16.33px 30px;
      border-radius: 5.62px;

      & p {
        font-size: 21px;
      }
    }
  }
}

// .slider__slide {
//   position: relative;
//   width: 100%;
//   display: flex;

//   @include maxWidth($mobile-xl) {
//     aspect-ratio: 0.763 / 1;
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     inset: 0;
//     background: rgba(0, 0, 0, 0.3);
//     z-index: 1;

//     @include maxWidth($mobile-xl) {
//       display: none;
//     }
//   }

//   &__text_content {
//     position: absolute;
//     left: 50%;
//     transform: translateX(-50%);
//     bottom: 60px;
//     width: max-content;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     z-index: 11;
//     text-align: center;

//     @include maxWidth($mobile-xl) {
//       bottom: 30%;
//     }

//     & button {
//       @include maxWidth($mobile-xl) {
//         font-size: 21px;
//       }
//     }

//     &__enter {
//       transform: translateY(-150px);
//     }

//     &__enterActive {
//       transform: translateY(0);
//       transition: transform 0.3s linear;
//     }

//     &__exit {
//       transform: translateY(0);
//     }

//     &__exitActive {
//       transform: translateY(-150px);
//       transition: transform 0.3s linear;
//     }
//   }

//   & img {
//     object-fit: cover;
//   }
// }
