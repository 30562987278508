@import 'assets/styles/_mixins.scss';

.swiper {
  &__arrows {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    padding: 0 16px 16px;
  }

  &__arrow {
    display: flex;
    width: 35px;
    height: 35px;
    border: 1px solid $white;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: $white;
    transition: color 0.1s linear, border-color 0.1s linear;

    @include maxWidth($mobile-xl) {
      width: 30px;
      height: 30px;

      & svg {
        width: 15px;
        height: 11px;
      }
    }

    &--next {
      transform: rotate(180deg);
    }

    @include minWidth($tablet-l) {
      &:hover,
      &:focus-visible {
        border-color: $blue;
        color: $blue;
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__wrapper {
    position: relative;
  }
}
