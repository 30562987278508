@import './assets/styles/_mixins.scss';

.supportLinks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 95px;
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.02em;

  @include maxWidth($mobile-xl) {
    margin-top: 65px;
    font-size: 16px;
    letter-spacing: -0.02em;
    svg {
      width: 24px;
      height: auto;
    }
  }

  &__link {
    display: flex;
    gap: 17px;
    align-items: center;
  }
}
