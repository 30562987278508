@import './assets/styles/mixins';

.partners_page {
  padding-top: 29px;
  max-width: 1487px;

  &__title {
    text-align: center;
  }

  &__categories {
    padding: 0;
    max-width: 100%;
    margin: 55px 0 108px;
    justify-content: center;

    @include maxWidth($desktop-s) {
      margin-top: 38px;
    }

    @include maxWidth($tablet-m) {
      margin: 38px 0 85px 0;
    }

    @include maxWidth($mobile-xl) {
      margin: 38px 0 51.54px 0;
    }
  }
}
